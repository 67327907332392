// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails, { $ } from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import hljs from 'highlightjs';
import ActiveMenuLink from "active-menu-link";
// import { ContextExclusionPlugin } from "webpack";
import CookiesEuBanner from "cookies-eu-banner";
window.CookiesEuBanner = CookiesEuBanner;

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var currentTheme = 'light';
var stored = localStorage.getItem('currentTheme');
if (stored && (stored == 'light' || stored == 'dark')) {
  currentTheme = stored;
}

document.addEventListener("turbolinks:load", function() {
  const stickyNav = document.getElementById('sticky-nav');
  if (stickyNav) {
    new ActiveMenuLink(".sticky-nav", {activeClass: "sticky-nav-active"});
  }
    
  hljs.initHighlightingOnLoad();
  Array.from(document.getElementsByTagName("code")).forEach((block) => {
      hljs.highlightBlock(block);
  });

  const themeSwitch = document.getElementById('theme-switcher');
  const themeSlider = document.getElementById('switch');

  themeSlider.addEventListener('click', () => {
    if (currentTheme == 'light') {
      themeSwitch.checked = true;
      localStorage.setItem('currentTheme', 'dark');
      setDark();
      currentTheme = 'dark';
    } else { 
      themeSwitch.checked = false;
      localStorage.setItem('currentTheme', 'light');
      setLight();
      currentTheme = 'light';
    }
  });

  function setDark() {
    Array.from(document.getElementsByClassName('light')).forEach((item) => {
      item.classList.remove('light');
      item.classList.add('dark');
    });
  }
  function setLight() {
    Array.from(document.getElementsByClassName('dark')).forEach((item) => {
      item.classList.remove('dark');
      item.classList.add('light');
    });
  }

  if (currentTheme == 'light') {
    themeSwitch.checked = false;
    localStorage.setItem('currentTheme', 'light');
    setLight();
  } else if (currentTheme == 'dark') { 
    themeSwitch.checked = true;
    localStorage.setItem('currentTheme', 'dark');
    setDark();
  }
  
  window.likeArticle = function(slug) {
    Rails.ajax({
      url: "/like_article/" + slug,
      type: "post",
      success: function(data) { showLikeFeedback(); },
      error: function(data) { }
    });
  }
  
  window.dislikeArticle = function(slug) {
    Rails.ajax({
      url: "/dislike_article/" + slug,
      type: "post",
      success: function(data) { showLikeFeedback(); },
      error: function(data) { }
    });
  }
  
  window.likeTopic = function(slug) {
    Rails.ajax({
      url: "/like_topic/" + slug,
      type: "post",
      success: function(data) { showLikeFeedback(); },
      error: function(data) { }
    });
  }
  
  window.dislikeTopic = function(slug) {
    Rails.ajax({
      url: "/dislike_topic/" + slug,
      type: "post",
      success: function(data) { showLikeFeedback(); },
      error: function(data) { }
    });
  }
  
  function showLikeFeedback() {
    var feedbackBlockActions = document.getElementById('feedback-block-actions');
    feedbackBlockActions.innerHTML = '<p class="meta">Thank you for your feedback</p>'
  }

});
